<template>
 
    <router-view />
</template>

<style>
#app {
  font-family: 'Montserrat', sans-serif; 
  height: 100%;
}
html, body {
    height: 100%;
}

body {
  margin: 0;
  padding: 0;
}
</style>
