
import {
    defineComponent,
    ref
} from "vue";
import HeaderPage from "@/components/Header.vue";

export default defineComponent({
    name: "HomePage",
    components: {
        HeaderPage,
    },
    setup() {
        const disabled = ref(false);

        const count = ref < number > (0);
        let colspan = ref("contexte-open");
        let value_col = true;
        let contexte_active = ref("");

        function increment(contexte: any) {
            console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
            disabled.value = true;

            if (value_col) {
                colspan.value = contexte + "-open";
                value_col = !value_col;
            } else {
                colspan.value = contexte + "-close";
                value_col = !value_col;
            }
        }

        return {
            value_col,
            count,
            colspan,
            disabled,
            increment,
        };
    },
    mounted() {
        this.value_col = true;
    },
});
