import { createStore } from 'vuex'
var moment = require('moment'); 
export default createStore({
  state: {
    ps: 0,
    filelist : [],
    addcomplement : "",
    complainte: [
      {
        data: "",
        key_list: "@",
        metadata: "",
        complaint_id: "",
        complaint_status_id: "",
      }
    ],

  },
  mutations: {
    setPs(state, payload) {
      state.ps = payload
    },
    setFileList(state,payload) {
      state.filelist = payload
    },
    setAddcomplement(state,payload) {
      state.addcomplement = payload
    },
    disposeComplaint(state) {
      state.complainte = [];
    },
    setComplaint(state, payload) {
      console.log(payload[0].data.description);
      moment().format("2022-03-02");
      moment().format("dddd, MMMM Do YYYY, h:mm:ss a")
      
      payload.forEach((element: { data: string; status: any; date: string; metadata: any; complaint_id: any; complaint_status_id: any; }) => {
        let oneComplaint = {
          data: element.data,
          status: element.status,
          description: element.data.split("\"description\":")[1].split("\",\"files\"")[0].split("\"")[1],
          key_list: element.date,
          date: (moment(element.date.split("T")[0], 'YYY-MM-DD').format('DD/MMM')).replace("/", " "),

          // var new_date = moment(element.date, "YYY-MM-DD").add(1, 'days');

          metadata: element.metadata,
          complaint_id: element.complaint_id,
          complaint_status_id: element.complaint_status_id,
        }
        if(oneComplaint.data!=="" ){
          state.complainte.push(oneComplaint)
        }
      });
      console.log(state.complainte);
    }
  },
  actions: {
    setPsAction({ commit }, payload) {
      commit('setPs', payload)
    },
    setAddcomplementAction({ commit }, payload) {
      commit('setAddcomplement', payload)
    },
    setsetFileList({ commit }, payload) {
      commit('setFileList', payload)
    },
    setComplaintAction({ commit }, payload) {
      commit('setComplaint', payload)
    },
    disposeComplaintAction({ commit }) {
      commit('disposeComplaint')
    },
  },
  modules: {
  },
  getters: {
    ps(state) {
      return state.ps;
    },
    complaintList(state) {
      return state.complainte
    }
  }
    
})
