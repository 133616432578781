
import HeaderPage from "@/components/Header.vue";
import { defineComponent, Ref, ref } from "vue";

export default defineComponent({
  name: "HomePage",
  components: {
    HeaderPage,
  },
  setup() {
    let mobile = ref("true");
    return {
      mobile
    };
  },

});
