
import HeaderPage from "@/components/Header.vue";
import { defineComponent, Ref, ref } from "vue";

export default defineComponent({
  name: "HomePage",
  components: {
    HeaderPage,
  },
  setup() {
    let mobile = ref("true");
    return {
      mobile
    };
  },
//   mounted() {
//     var isTouchScreen = 'ontouchstart' in window 

// if(isTouchScreen === true) {
//   var element = document.getElementById("nav");
//   element?.classList.remove("active");
//   this.mobile = "true"
//   // alert(this.mobile)
// } else {
  
//   this.mobile = "false"
//   alert(this.mobile)
// }
//   }
});
