
import {
    defineComponent,
    ref
} from "vue";
import { useRouter, useRoute } from "vue-router";


export default defineComponent({
    name: "HeaderPage",
    components: {},
    setup() {
        const router = useRouter();
        let a_acactived = ref(false);
        let a_about = ref(false);
        let affichage = ref("false")
        // this.$router.push({ name: "Complaint" });
        function homepage() {
          console.log("homepage");
          router.push({ name: "home" });
         
        }
        function mobileMenu(event:any) {
            if(event === "display") {
                affichage.value="false"
                router.push({ name: "mobile" });
            }else {
                affichage.value="true"
                router.push({ name: "home" });
            }
        }
        function selectmenu(event: any) {
            if (event === "accueil") {
                a_acactived.value = true;
                a_about.value = false;
            }
            if (event === "about") {
                a_acactived.value = false;
                a_about.value = true;
            }
        }
        return {
            selectmenu,
            a_acactived,
            a_about,
            homepage,
            mobileMenu,
            affichage
        };
    },
    mounted() {
        this.affichage ="true"
    }
});
