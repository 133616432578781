
import { defineComponent, Ref, ref } from "vue";
import HeaderPage from "@/components/Header.vue";
var _ = require("lodash");
const axios = require("axios").default;
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  name: "HomePage",
  components: {
    HeaderPage,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    let modalEle = ref(null);
    const store = useStore();
    let thisModalObj = null;
    let filesUploadProgressbar = ref<any>([]);
    const disabled = ref(false);
    const root = ref();
    let focus_search=ref(false);
    let result_search = ref(false);
    const count = ref<number>(0);
    let colspan = ref("tt");
    let institutionSelected = ref("tt")
    let value_col = ref(true);
    let progress: any = ref();
    const max = 100;
    let allFile2: any = [];
    let progrss_allFile2 = ref<any>([]);
    const fileToApi = "";
    let showModal = ref(false);
    let available =[
      'HTML',
      'CSS',
    ];
let empty = ref("false");
let modalDataCtrl=ref("")
    let codesuivi = "";
    const status = ref("");
    const files = [];
    let institutions= ref();
    let institutions_original = ref();
    let show = ref();
    let filesApi: any = [];
    let codesecret = ref();
    let organisme = ref();
    let serachTest = ref();
    let people = ref();
    let description = ref();
    let section = ref({});
    let plainte_already=ref();
    let lastname = ref();
    let organisme_last = ref();
    let firstname = ref();
    let phone = ref();
    let email = ref();
    function resultsBox() {
      console.log("ee");
      
      return document.querySelector(".result-box")
    }
    function inputBox() {
      return document.getElementById("input-box")
    }
    function search() {
      // focus_search.value=true;
    let result = institutions.value
    let oldSearch = ""
    // console.log(serachTest.value);  
    institutions.value=institutions_original.value.filter((institution: { label: any; })=>institution.label.toLowerCase().includes(serachTest.value.toLowerCase()))
    if (institutions.value.length===0) {
      institutions.value = [{
        label:"Aucun organisme ne répond à votre critère de recherche.",
        id:"10"
      }]
    }
    console.log(institutions_original);
  
    //   oldSearch = serachTest.value
  //   if(oldSearch!=serachTest.value) {
  //   institutions = result
  //  }

    }
    function setFocus() { 
      focus_search.value=true
      result_search.value=true
    }
    function handleBlur() {
      focus_search.value=false
      // result_search.value=false
    }
    function chargerfile() {
      document.getElementById("getFile")?.click();
    }
    function choix_organisme(event:any) {
console.log(event);

    }
    function submit() {
      show.value = "show";
      console.log(show.value);
    }
    function update(event: any) {
      if(event==="ok") {
        let nom = lastname.value;
        if (
          Boolean(lastname.value) === false ||
          Boolean(firstname.value) === false ||
          Boolean(email.value) === false ||
          Boolean(phone.value) === false
        ) {
          modalDataCtrl.value = "true"
        } else {
          modalDataCtrl.value = "false"
          let result = {
            lastname: nom,
            firstname: firstname.value,
            email: email.value,
            phone: phone.value,
            fichiers: filesApi,  
          subject: people.value,
          questioned: people.value,
          description: description.value,
          };
         console.log(result);
         
          axios
            .post(
              `https://app.denonciation.bj/api/denonciation_infos/${codesuivi}`,
              result
            )
            .then(function (response: any) {
              console.log(response.data);
              return response.data;
            })
            .catch(function (error: any) {
              // alert(error);
              return error;
            });
          router.push({ name: "confirmation" });
        }
      }
      else{
        modalDataCtrl.value = "false"
          let result = {
            lastname: lastname,
            firstname: firstname,
  
            email: email,
            phone: phone,
            fichiers: filesApi,
 

            subject: people.value,
          questioned: people.value,
          description: description.value,
          };
        axios
            .post(
              `https://app.denonciation.bj/api/denonciation_infos/${codesuivi}`,
              result
            )
            .then(function (response: any) {
              console.log(response.data);
              return response.data;
            })
            .catch(function (error: any) {
              // alert(error);
              return error;
            });
          router.push({ name: "confirmation" });
      }
    }
    function maxLengthCheck(object:any) {
      console.log(codesecret.value?.toString().length);
      
       if (codesecret.value?.toString().length >3) {
        // console.log("yesss",codesecret.value.toString().slice(0,3));
        
        codesecret.value=codesecret.value.toString().slice(0,3)
       }
      // object.value = object.value.slice(0, object.maxLength)
    }
    async function uplaod2() {
      for (let index = 0; index < allFile2.length; index++) {
        const filename = allFile2[index].name;
        const config = {
          onUploadProgress: (progressEvent: any) => {
            filesUploadProgressbar[filename] = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            filesUploadProgressbar.value = Object.assign(
              {},
              filesUploadProgressbar
            );
          },
        };
        const element = allFile2[index];
        let formData = new FormData();
        formData.append("fichier", element);
        try {
          const res = await axios.post(
            "https://app.denonciation.bj/api/addfile",
            formData,
            config,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );
          if (!res.data.error) {
            filesApi.push({
              label: res.data.message.label,
              filename: res.data.message.filename,
            });
          }

          store.dispatch("setsetFileList", filesApi);
        } catch (error) {
          // fileUploadError[filename] = true
        }
      }
    }

    async function submited() {
      console.log("CLICKED");
      let userAgent = navigator.userAgent;
      let browserName;
let browserPrint;
      if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = "chrome";
      } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = "firefox";
      } else if (userAgent.match(/safari/i)) {
        browserName = "safari";
      } else if (userAgent.match(/opr\//i)) {
        browserName = "opera";
      } else if (userAgent.match(/edg/i)) {
        browserName = "edge";
      } else {
        browserName = "No browser detection";
      }
      if ( (people.value===undefined) ||  (description.value===undefined) || (codesecret.value===undefined) ) {
        empty.value="true"
        console.log( people.value,description.value,codesecret.value);
        
        // TODO Toast error
      } else {
        browserPrint = await axios({
          method: "get",
          url: "https://api.db-ip.com/v2/free/self",
        });
       browserPrint["browser"] = browserName;
        console.log(browserName, browserPrint);
        empty.value="false"
        let result = {
          lastname: "",
          firstname: "",
          fichiers: filesApi,
          birthday: codesecret.value,
          subject: people.value,
          questioned: people.value,
          description: description.value,
          metadata: browserPrint
        };
console.log(result);

        let r = axios
          .post(
            "https://app.denonciation.bj/api/denonciation_with_birthday",
            result
          )
          .then(function (response: any) {
            // console.log(response.data["message"]);

            return response.data["message"];
          })
          .catch(function (error: any) {
            // alert(error);
            return error;
          });
        r.then((response: any) => {
          console.log(response);
          codesuivi = response;
          store.dispatch("setPsAction", codesuivi);
          show.value = "show";
        });

        // this.showModal = true;
      }
    }
    function onFileChanged(event: any) {
      let oldfile = allFile2;
      // console.log(oldfile.length);
      if (oldfile.length == 0) {
        allFile2 = event.target.files;
        progrss_allFile2.value = allFile2;
      } else {
        allFile2 = [];
        oldfile = Array.from(oldfile);
        // console.log("yoyoooyyooy", oldfile, Array.from(event.target.files));
        let newfile = Array.from(event.target.files);
        newfile.forEach((element: any) => {
          let indexUpload = _.findIndex(allFile2, { name: element.name });
          if (indexUpload < 0) {
            allFile2.push(element);
            progrss_allFile2.value = Array.from(progrss_allFile2.value);
            progrss_allFile2.value.push(element);
          }
        });
      }
      console.log("kk", progrss_allFile2.value);

      uplaod2();
    }

    function cancelUpload(event: any) {
      var other = _.findIndex(progrss_allFile2.value, { name: event.name });

      let files = Array.from(progrss_allFile2.value);
      (progrss_allFile2.value = _.difference(files, files.splice(other, 1))),
        other;

      filesApi = Array.from(filesApi);
      let indexUpload = _.findIndex(filesApi, { label: event.name });
      let filesObject = _.difference(filesApi, filesApi.splice(indexUpload, 1));
      //this.$store.dispatch("setsetFileList", filesObject);
      console.log(filesApi);
    }
   function selectInstitution(event:any) {
    if (event!='Aucun organisme ne répond à votre critère de recherche.')  {
      serachTest.value = event;
     result_search.value=false
    }
 
   }
  
     async function getInstitution():Promise<string[]> {
       let r = await axios
          .get( `https://app.denonciation.bj/api/institutions` )
          .then(function (response: any) {
            // console.log(response.data.institutions[10].id);
            return response.data.institutions;
          })
          .catch(function (error: any) {
            return error;
          });
          console.log(r);
      return r;
}
    return {
      focus_search,
      institutions,
      institutions_original,
      progress,
      value_col,
      institutionSelected,
      count,
      colspan,
      disabled,
      search,
      selectInstitution,
      result_search,
      setFocus,
      handleBlur,
      chargerfile,
      resultsBox,
      inputBox,
      onFileChanged,
      update,
      getInstitution,
      allFile2,
      progrss_allFile2,
      max,
      show,
      cancelUpload,
      submit,
      submited,
      maxLengthCheck,
      empty,
      serachTest,
      organisme_last,
      showModal,
      status,
      section,
      codesecret,
      lastname,
      firstname,
      phone,
      email,
      organisme,
      people,
      description,
      filesUploadProgressbar,
      plainte_already,
      modalDataCtrl,
      choix_organisme
    };

  },
  async mounted() {
    this.value_col = true;
    this.focus_search=false;
    this.result_search=false;
    this.show = "display:none";
    this.empty="false";
    this.institutions = await this.getInstitution();
    
    // this.institutions =  this.institutions.sort()
    this.institutions = _.orderBy(this.institutions, ['label'],['asc']);
    this.institutions_original = this.institutions 
    console.log( this.institutions);
    
    

  },
});
