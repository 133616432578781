
import { defineComponent, ref, computed } from "vue";
import HeaderPage from "@/components/Header.vue";
var _ = require("lodash");
const axios = require("axios").default;
import { useStore } from "vuex";
export default defineComponent({
  name: "HomePage",
  components: {
    HeaderPage,
  },
  setup() {
    const store = useStore();

    let filesUploadProgressbar: any[];
    const disabled = ref(false);
    const root = ref();
    const count = ref<number>(0);
    let colspan = ref("tt");
    let value_col = ref(true);
    let progress = ref();
    const max = 100;
    let allFile2: any;
    let progrss_allFile2 = ref();
    const fileToApi = "";
    const showModal = false;
    const showAlert2 = false;
    const fisrt_modal = true;
    const showAlert = false;
    const codesuivi = "";
    const status = "";
    const files = [];
    const filesApi = [];

    let ps = computed(() => store.getters.ps);

    function increment() {
      console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
      disabled.value = true;
      value_col.value = !value_col.value;

      if (value_col.value === false) {
        colspan.value = "display:none";
      } else {
        colspan.value = "display:block";
      }
    }

    return {
      progress,
      value_col,
      count,
      colspan,
      disabled,
      increment,
      allFile2,
      progrss_allFile2,
      ps,
    };
  },
  mounted() {
    this.value_col = true;
  },
});
