
import { defineComponent, ref } from "vue";
import { useRouter, useRoute } from "vue-router";

import HeaderPage from "@/components/Header.vue";
var _ = require("lodash");
const axios = require("axios").default;
import { useStore } from "vuex";

export default defineComponent({
  name: "HomePage",
  components: {
    HeaderPage,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    let reference = ref();
    let empty =ref("");
    let date = ref();
    let filesUploadProgressbar: any[];
    const disabled = ref(false);
    const root = ref();
    const count = ref<number>(0);
    let colspan = ref("tt");
    let value_col = ref(true);
    let progress = ref();
    const max = 100;
    let allFile2: any;
    let progrss_allFile2 = ref();
    const fileToApi = "";
    const showModal = false;
    const showAlert2 = false;
    const fisrt_modal = true;
    const showAlert = false;
    const codesuivi = "";
    const status = "";
    const files = [];
    const filesApi = [];

    function increment() {
      console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
      disabled.value = true;
      value_col.value = !value_col.value;

      if (value_col.value === false) {
        colspan.value = "display:none";
      } else {
        colspan.value = "display:block";
      }
    }
    function follow() {
      // console.log(this.section.reference);
      if((reference.value===undefined) ||  (date.value==undefined)) {
        empty.value = "true"
      }else {
        empty.value = "false"
        let url = `https://app.denonciation.bj/api/denonciation/${reference.value}/${date.value}`;
        // alert(JSON.stringify(this.section));
        let r = axios
          .get(url)
          .then(function (response: any) {
            // console.log(response.data['message']);
            return response.data["message"];
          })
          .catch(function (error: any) {
            alert(error);
            return error;
          });
        r.then((response: any) => {
          // this.codesuivi = response;
          console.log(response);
          if (response === "Veuillez bien spécifier une reference correcte") {
            store.dispatch("setAddcomplementAction", false);
          } else {
            store.dispatch("setAddcomplementAction", true);
          }
          store.dispatch("setPsAction", reference.value);
          store.dispatch("setComplaintAction", response);
        });
        router.push({ name: "reportList" });
      }
      
    }
    return {
      progress,
      store,
      value_col,
      count,
      colspan,
      disabled,
      increment,
      allFile2,
      progrss_allFile2,
      follow,
      reference,
      date,
      empty
    };
  },
  mounted() {
    this.value_col = true;
    this.store.dispatch("disposeComplaintAction");
  },
});
