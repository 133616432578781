import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomePage from '@/components/HomePage.vue'
import AboutPage from '@/components/AboutPage.vue'
import FaqPage from '@/components/FaqPage.vue'
import ReportPage from '@/components/ReportPage.vue'
import FollowPage from "@/components/FollowPage.vue"
import ConfirmationPage from "@/components/confirmation.vue"
import ReportList from "@/components/ReportList.vue"
import ComplementPage from "@/components/ComplementPage.vue"
import MobileMenu from "@/components/MobileMenu.vue"


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/mobile',
    name: 'mobile',
    component: MobileMenu
  },
  {
    path: '/complement',
    name: 'complement',
    component: ComplementPage
  },
  {
    path: '/about',
    name: 'about',
    component: AboutPage
  },
  {
    path: '/faq',
    name: 'faq',
    component: FaqPage
  },
  {
    path: '/report',
    name: 'report',
    component: ReportPage
  },
  {
    path: '/follow',
    name: 'follow',
    component: FollowPage
  },
  {
    path: '/confirmation',
    name: 'confirmation',
    component: ConfirmationPage
  },
  {
    path: '/reportList',
    name: 'reportList',
    component: ReportList
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active"
})

export default router
