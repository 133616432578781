import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-305bde3e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "top_header" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "footer" }
const _hoisted_4 = { class: "entete-reportList" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "contexte" }
const _hoisted_7 = { class: "content-context" }
const _hoisted_8 = { class: "content-context_date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderPage = _resolveComponent("HeaderPage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_HeaderPage),
      _createElementVNode("footer", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, " Historique de traitement de la plainte nº " + _toDisplayString(_ctx.ps), 1),
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/complement'))),
            class: "complement"
          }, " Completer des informations ")
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.result, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.key_list
          }, [
            _withDirectives(_createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(item.description), 1),
              _createElementVNode("div", _hoisted_8, _toDisplayString(item.date), 1)
            ], 512), [
              [_vShow, item.key_list !== '@']
            ])
          ]))
        }), 128))
      ])
    ])
  ]))
}