
import { defineComponent, computed, ref } from "vue";
import HeaderPage from "@/components/Header.vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "HomePage",
  components: {
    HeaderPage,
  },
  setup() {
    const disabled = ref(false);
    let result = ref();
    const store = useStore();
    const count = ref<number>(0);
    let colspan = ref("tt");
    let value_col = ref(true);
    let contexte_active = ref("");

    function increment() {
      console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
      disabled.value = true;
      value_col.value = !value_col.value;

      if (value_col.value === false) {
        colspan.value = "display:none";
      } else {
        colspan.value = "display:block";
      }
    }
    let ps = computed(() => store.getters.ps);

    return {
      value_col,
      count,
      colspan,
      disabled,
      increment,
      store,
      result,
      ps,
    };
  },


  async mounted() {
    this.value_col = true;
    // this.store.dispatch("disposeComplaintAction");
    this.result = await this.store.getters.complaintList;
    // this.result=this.result.shift()
    // console.log("resulte", this.result);
    // this.store.dispatch("disposeComplaintAction");
  },

});
