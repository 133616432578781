
import { defineComponent, Ref, ref } from "vue";
import HeaderPage from "@/components/Header.vue";
var _ = require("lodash");
const axios = require("axios").default;
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router"; 
export default defineComponent({
  name: "HomePage",
  components: {
    HeaderPage,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    let filesUploadProgressbar = ref<any>([]);
    const disabled = ref(false);
    const root = ref();
    const count = ref<number>(0);
    let colspan = ref("tt");
    let value_col = ref(true);
    let progress: any = ref();
    const max = 100;
    let allFile2: any = [];
    let progrss_allFile2 = ref<any>([]);
    const fileToApi = "";
    let showModal = ref(false);
    let ps: any;
    let codesuivi = "";
    const status = ref("");
    const files = [];
    let show = ref();
    let filesApi: any = [];
    let codesecret = ref();
    let organisme = ref();
    let people = ref();
    let description = ref();
    let section = ref({});

    let lastname = ref();
    let firstname = ref();
    let phone = ref();
    let email = ref();
    function chargerfile() {
      document.getElementById("getFile")?.click();
    }
    function submit() {
      show.value = "show";
      console.log(show.value);
    }

    async function uplaod2() {
      for (let index = 0; index < allFile2.length; index++) {
        const filename = allFile2[index].name;
        const config = {
          onUploadProgress: (progressEvent: any) => {
            filesUploadProgressbar[filename] = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            filesUploadProgressbar.value = Object.assign(
              {},
              filesUploadProgressbar
            );
          },
        };
        const element = allFile2[index];
        let formData = new FormData();
        formData.append("fichier", element);
        try {
          const res = await axios.post(
            "https://app.denonciation.bj/api/addfile",
            formData,
            config,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );
          if (!res.data.error) {
            filesApi.push({
              label: res.data.message.label,
              filename: res.data.message.filename,
            });
          }

          //this.$store.dispatch("setsetFileList", this.filesApi);
        } catch (error) {
          // fileUploadError[filename] = true
        }
      }
    }

    async function submited() {
      let userAgent = navigator.userAgent;
      let browserName;
let browserPrint;
      if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = "chrome";
      } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = "firefox";
      } else if (userAgent.match(/safari/i)) {
        browserName = "safari";
      } else if (userAgent.match(/opr\//i)) {
        browserName = "opera";
      } else if (userAgent.match(/edg/i)) {
        browserName = "edge";
      } else {
        browserName = "No browser detection";
      }
      //  alert(store.getters.ps)
      console.log(codesecret.value);
      if (Boolean(description) === false) {
        // this.showAlert = true;
        console.log("erreur");
      } else {
        browserPrint = await axios({
          method: "get",
          url: "https://api.db-ip.com/v2/free/self",
        });
       browserPrint["browser"] = browserName;
        console.log(browserName, browserPrint);
        let result = {
          lastname: "",
          firstname: "",
          fichiers: filesApi,
          birthday: codesecret.value,
          subject: organisme.value,
          questioned: organisme.value,
          description: description.value,
          metadata: browserPrint
        };

        console.log(ps);

        let r = axios
          .post(
            `https://app.denonciation.bj/api/denonciation/${store.getters.ps}`,
            result
          )
          .then(function (response: any) {
            // console.log(response.data["message"]);

            return response.data["message"];
          })
          .catch(function (error: any) {
            // alert(error);
            return error;
          });
        r.then((response: any) => {
          console.log(response);
          codesuivi = response;
          // store.dispatch("setPsAction", codesuivi);
          show.value = "show";
        });
        router.push({ name: "confirmation" });
        // this.showModal = true;
      }
    }
    function onFileChanged(event: any) {
      let oldfile = allFile2;
      // console.log(oldfile.length);
      if (oldfile.length == 0) {
        allFile2 = event.target.files;
        progrss_allFile2.value = allFile2;
      } else {
        allFile2 = [];
        oldfile = Array.from(oldfile);
        // console.log("yoyoooyyooy", oldfile, Array.from(event.target.files));
        let newfile = Array.from(event.target.files);
        newfile.forEach((element: any) => {
          let indexUpload = _.findIndex(allFile2, { name: element.name });
          if (indexUpload < 0) {
            allFile2.push(element);
            progrss_allFile2.value = Array.from(progrss_allFile2.value);
            progrss_allFile2.value.push(element);
          }
        });
      }
      console.log("kk", progrss_allFile2.value);

      uplaod2();
    }

    function cancelUpload(event: any) {
      var other = _.findIndex(progrss_allFile2.value, { name: event.name });

      let files = Array.from(progrss_allFile2.value);
      (progrss_allFile2.value = _.difference(files, files.splice(other, 1))),
        other;

      filesApi = Array.from(filesApi);
      let indexUpload = _.findIndex(filesApi, { label: event.name });
      let filesObject = _.difference(filesApi, filesApi.splice(indexUpload, 1));
      //this.$store.dispatch("setsetFileList", filesObject);
      console.log(filesApi);
    }

    return {
      store,
      ps,
      progress,
      value_col,
      count,
      colspan,
      disabled,
      chargerfile,
      onFileChanged, 
      allFile2,
      progrss_allFile2,
      max,
      show,
      cancelUpload,
      submit,
      submited,
      showModal,
      status,
      section,
      codesecret,
      lastname,
      firstname,
      phone,
      email,
      organisme,
      people,
      description,
      filesUploadProgressbar,
    };
  },
  async mounted() {
    this.value_col = true;
    this.show = "display:none";
    this.ps = await this.store.getters.ps;
    this.store.dispatch("disposeComplaintAction");
  },
});
